<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form @submit.prevent="saveEmailTemplate()">
      <div class="row">
        <div class="col-lg-8">
          <div class="col-lg-12">
            <div class="card" style="margin-bottom: 0">
              <div class="card-body">
              <div class="row">
                <div class="col-lg-8">
                  <h4 class="card-title mb-4">Preview</h4>
                </div>
                <div class="col-lg-4">
                  <div class="text-sm-right">
                    <button type="button" class="btn btn-dark mb-2 mr-2" @click="popupEmailTest()">
                      <i class="mdi mdi-plus mr-1"></i> Enviar e-mail de teste
                    </button>
                    <button type="button" class="btn btn-warning mb-2 mr-2" @click="sendNotifications()" v-if="emailtemplate.type_email === 5">
                      <i class="mdi mdi-plus mr-1"></i> Notificar todos usuários
                    </button>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-10">

                <!-- <div class="d-flex mb-4">
                  <div class="flex-grow-1">
                    <h5 class="font-size-14 mt-1">
                      <span class="text-muted">De:</span>
                      {{ emailtemplate.from_name }}
                      <small class="text-muted"><span aria-hidden="true">&lt;</span>{{emailtemplate.from_email}}<span aria-hidden="true">&gt;</span></small>
                    </h5>
                    <h5 class="font-size-14 mt-1">
                      <span class="text-muted">Assunto:</span>
                      {{ emailtemplate.subject }}
                    </h5>
                  </div>
                </div> -->
                <div class="row mt-3 justify-content-center">
                  <div class="col-lg-10" style="max-width: 600px" v-show="imageUploaded">
                    <img :src="emailtemplate.image_header" :alt="emailtemplate.image_header_alt" :title="emailtemplate.image_header_alt" width="100%" style="vertical-align: top;border-radius: 2px 2px 0 0;width: 100%;object-fit: cover;">
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-lg-10"  style="max-width: 600px">
                    <div class="card" style="box-shadow: 0 2px 50px 0 rgb(0 0 0 / 10%); min-height: 600px;">
                      <div class="card-body p-5">
                        <span v-html="emailtemplate.content"></span>
                        <div class="footerEmail">
                          <div class="col-lg-12 mt-3 mb-4">
                            <span v-if="emailtemplate.url_facebook">
                              <a :href="emailtemplate.url_facebook" target="_blank" aria-label="Acesse nosso Facebook">
                                <img src="https://xcode-eventfy.sfo3.digitaloceanspaces.com/eventfy/email/facebook.png" style="height:32px;width:32px;margin-right: 10px" height="32" width="32" alt="Facebook" title="Facebook" border="0">
                              </a>
                            </span>
                            <span v-if="emailtemplate.url_instagram">
                              <a :href="emailtemplate.url_instagram" target="_blank" aria-label="Acesse nosso Instagram">
                                <img src="https://xcode-eventfy.sfo3.digitaloceanspaces.com/eventfy/email/instagram.png" style="height:32px;width:32px;margin-right: 10px" height="32" width="32" alt="Instagram" title="Instagram" border="0">
                              </a>
                            </span>
                            <span v-if="emailtemplate.url_linkedin">
                              <a :href="emailtemplate.url_linkedin" target="_blank" aria-label="Acesse nosso Linkedin">
                                <img src="https://xcode-eventfy.sfo3.digitaloceanspaces.com/eventfy/email/linkedin.png" style="height:32px;width:32px;" height="32" width="32" alt="Linkedin" title="Linkedin" border="0">
                              </a>
                            </span>
                          </div>
                          <span class="text-muted">{{ emailtemplate.organizer }}</span>
                          <p class="text-muted mt-1">
                            {{ emailtemplate.address_complete }}
                            {{ emailtemplate.complement ? ' ' + emailtemplate.complement + ',' : '' }}
                            {{ emailtemplate.city }}{{ emailtemplate.district ? ', ' + emailtemplate.district : '' }}
                            {{ emailtemplate.country ? '- ' + emailtemplate.country : '' }}
                          </p>
                          <p class="font-size-11 mt-3">
                            <a href="#" style="color: #333" target="_blank" alt="Cancelar subscrição">
                              Cancelar subscrição
                            </a>
                            |
                            <a :href="emailtemplate.url_privacy_policy" style="color: #333" target="_blank" alt="Política de privacidade">
                              Política de privacidade
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4" style="overflow: hidden scroll; max-height: 980px">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Informações do E-mail</h4>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="from_name">De</label>
                      <input
                        type="text"
                        name="from_name"
                        id="from_name"
                        class="form-control"
                        v-model="emailtemplate.from_name"
                        :class="{'is-invalid': submitted && $v.emailtemplate.from_name.$error}"
                      >
                      <div v-if="submitted && !$v.emailtemplate.from_name.required" class="invalid-feedback">
                        O campo é obrigatório.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="from_email">Endereço de e-mail de resposta</label>
                      <input
                        type="text"
                        name="from_email"
                        id="from_email"
                        class="form-control"
                        v-model="emailtemplate.from_email"
                        :class="{'is-invalid': submitted && $v.emailtemplate.from_email.$error}"
                      >
                      <div v-if="submitted && !$v.emailtemplate.from_email.required" class="invalid-feedback">
                        O campo é obrigatório.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="subject">Assunto</label>
                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        class="form-control"
                        v-model="emailtemplate.subject"
                        :class="{'is-invalid': submitted && $v.emailtemplate.subject.$error}"
                      >
                      <div v-if="submitted && !$v.emailtemplate.subject.required" class="invalid-feedback">
                        O campo é obrigatório.
                      </div>
                    </div>
                  </div>
                </div>
                <h4 class="card-title mt-2 mb-4">Imagem de cabeçalho</h4>
                <div class="row">
                  <div class="col-lg-12 mb-2" v-show="imageUploaded">
                    <img :src="emailtemplate.image_header" :alt="emailtemplate.image_header_alt" :title="emailtemplate.image_header_alt" width="100%" style="vertical-align: top;border-radius: 2px 2px 0 0;width: 100%;object-fit: cover;">
                    <span type="button" class="btn btn-sm btn-danger btn-trash" @click="clearHeader()">
                         <i class="fas fa-trash-alt mr-1"></i>
                    </span>
                  </div>
                  <div class="col-lg-12" v-show="!imageUploaded">
                    <vue-dropzone
                      id="dropzone-header"
                      :options="dropzoneOptions"
                      :useCustomSlot="true" ref="dropzone"
                      v-on:vdropzone-sending="sendingEvent"
                      v-on:vdropzone-success="successUpload"
                      v-on:vdropzone-removed-file="removeFile"

                    >
                      <div class="dropzone-custom-content">
                        <h3 class="dropzone-custom-title">Adicionar imagem de cabeçalho</h3>
                        <div class="subtitle">Arraste e solte ou clique aqui para escolher uma imagem </div>
                      </div>
                    </vue-dropzone>

                    <p class="card-title-desc mt-1">Recomendamos uma imagem de pelo menos 2160 x 1080 pixels (proporção de 2:1), com no máximo 1 MB</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="image_header_alt">Texto alternativo da imagem</label>
                      <input
                        type="text"
                        name="image_header_alt"
                        id="image_header_alt"
                        class="form-control"
                        v-model="emailtemplate.image_header_alt"
                      >
                    </div>
                  </div>
                </div>
                <h4 class="card-title mt-2 mb-4">Corpo do e-mail</h4>
                <div class="row">
                  <div class="col-lg-12">
                    <ckeditor
                      :editor="editor"
                      v-model="emailtemplate.content"
                      :config="editorConfig"
                      :class="{
                        'is-invalid': submitted && $v.emailtemplate.content.$error,
                      }"
                    >
                    </ckeditor>
                  </div>
                </div>
                <h4 class="card-title mt-2 mt-4">Rodapé</h4>
                <p class="card-title-desc mt-1">Para ajudar seu e-mail a passar pelas caixas de entrada em vez de spam, preencha os campos abaixo:</p>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="organizer">Organizador</label>
                      <input
                        type="text"
                        name="organizer"
                        id="organizer"
                        class="form-control"
                        :class="{'is-invalid': submitted && $v.emailtemplate.organizer.$error}"
                        v-model="emailtemplate.organizer"
                      >
                      <div v-if="submitted && !$v.emailtemplate.organizer.required" class="invalid-feedback">
                        O campo é obrigatório.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="address_complete">Endereço</label>
                      <input
                        type="text"
                        name="address_complete"
                        placeholder="ex: Av. Brasil, 152"
                        id="address_complete"
                        class="form-control"
                        v-model="emailtemplate.address_complete"
                        :class="{'is-invalid': submitted && $v.emailtemplate.address_complete.$error}"
                      >
                      <div v-if="submitted && !$v.emailtemplate.address_complete.required" class="invalid-feedback">
                        O campo é obrigatório.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="complement">Complemento</label>
                      <input
                        type="text"
                        name="complement"
                        id="complement"
                        placeholder="ex: Prédio, Shopping"
                        class="form-control"
                        v-model="emailtemplate.complement"
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="city">Cidade</label>
                      <input
                        type="text"
                        name="city"
                        placeholder="ex: São Paulo"
                        id="city"
                        class="form-control"
                        :class="{'is-invalid': submitted && $v.emailtemplate.city.$error}"
                        v-model="emailtemplate.city"
                      >
                      <div v-if="submitted && !$v.emailtemplate.city.required" class="invalid-feedback">
                        O campo é obrigatório.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="district">Estado</label>
                      <input
                        type="text"
                        name="district"
                        placeholder="ex: São Paulo ou SP"
                        id="district"
                        class="form-control"
                        v-model="emailtemplate.district"
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="country">País</label>
                      <input
                        type="text"
                        name="country"
                        placeholder="ex: Brasil"
                        id="country"
                        class="form-control"
                        v-model="emailtemplate.country"
                        :class="{'is-invalid': submitted && $v.emailtemplate.country.$error}"
                      >
                      <div v-if="submitted && !$v.emailtemplate.country.required" class="invalid-feedback">
                        O campo é obrigatório.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="url_instagram">Link Instagram</label>
                      <input
                        type="text"
                        name="url_instagram"
                        id="url_instagram"
                        class="form-control"
                        v-model="emailtemplate.url_instagram"
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="url_linkedin">Link Linkedin</label>
                      <input
                        type="text"
                        name="url_linkedin"
                        id="url_linkedin"
                        class="form-control"
                        v-model="emailtemplate.url_linkedin"
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="url_facebook">Link Facebook</label>
                      <input
                        type="text"
                        name="url_facebook"
                        id="url_facebook"
                        class="form-control"
                        v-model="emailtemplate.url_facebook"
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="url_privacy_policy">Política de privacidade</label>
                      <input
                        type="text"
                        name="url_privacy_policy"
                        placeholder="URL política de privacidade"
                        id="url_privacy_policy"
                        class="form-control"
                        v-model="emailtemplate.url_privacy_policy"
                        :class="{'is-invalid': submitted && $v.emailtemplate.url_privacy_policy.$error}"
                      >
                      <div v-if="submitted && !$v.emailtemplate.url_privacy_policy.required" class="invalid-feedback">
                        O campo é obrigatório.
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="float-right">
                <a href="javascript:void(0)" @click="cancelar()" class="btn btn-dark mr-4">
                  Cancelar
                </a>
                <button type="submit" class="btn btn-primary mr-1">
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <modal-form v-model="modalForm" ref="formTestEmail"></modal-form>
  </Layout>
</template>
<script>
import Swal from 'sweetalert2'
import vueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import CKEditor from '@ckeditor/ckeditor5-vue'
import FullEditor from 'ckeditor5-build-full'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import * as storage from '@/modules/auth/storage'
import modalForm from './EmailTest.vue'
import service from './services'

export default {
  components: {
    ckeditor: CKEditor.component,
    Layout,
    vueDropzone,
    PageHeader,
    modalForm
  },
  name: 'templates',
  page: {
    title: 'Templates',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      editor: FullEditor,
      editorConfig: {
      },
      loading: true,
      imageUploaded: false,
      modalForm: false,
      submitted: false,
      dropzoneOptions: {
        url: process.env.VUE_APP_DEFAULT_AUTH + '/admin/settings/email/upload',
        thumbnailWidth: 600,
        addRemoveLinks: true,
        headers: { 'Authorization': 'bearer ' + this.token }
      },
      emailtemplate: {},
      title: 'Editar E-mail',
      items: [{
        text: 'E-mail',
        href: '/'
      },
      {
        text: 'Editar',
        active: true
      }
      ]
    }
  },
  validations: {
    emailtemplate: {
      from_name: { required },
      subject: { required },
      content: { required },
      organizer: { required },
      address_complete: { required },
      city: { required },
      country: { required },
      url_privacy_policy: { required },
      from_email: { required }
    }
  },
  mounted () {
    this.loadItem()
    this.$refs.dropzone.setOption('headers', { 'Authorization': 'bearer ' + this.token })
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('emailTemplate', { data: 'emailtemplate' }),
    token () {
      return storage.getLocalToken()
    }
  },
  methods: {
    ...mapActions('emailTemplate', {
      fetch: 'show',
      saveTemplate: 'save',
      destroyHeader: 'destroyHeader'
    }),
    removeFile (file, error, xhr) {
      this.emailtemplate.image_header = null
    },
    cancelar () {
      this.$router.push({
        name: 'settings-emails'
      })
    },
    async sendNotifications () {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, NOTIFICAR TODOS USUARIOS!'
      }).then(async result => {
        if (result.value) {
          try {
            await service.sendNotification().then(res => {
              let message = 'Sucesso: Em alguns minutos os e-mails serão enviados'
              let delay = 2500
              successMessage(message, delay)
              // this.close()
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
              this.loading = false
            })
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    async clearHeader () {
      let loader = this.$loading.show()
      try {
        this.submitted = true
        await this.destroyHeader(this.emailtemplate.id).then((data) => {
          let message = 'Sucesso: Imagem removida'
          let delay = 2500
          successMessage(message, delay)
          // this.emailtemplate.image_header = null
          this.imageUploaded = false
          this.$refs.dropzone.removeAllFiles()
          console.log(data)
          loader.hide()
        })
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
        loader.hide()
      }
    },
    successUpload (file, response) {
      this.emailtemplate.image_header = response.image_header
      this.imageUploaded = !!response.image_header
    },
    sendingEvent (file, xhr, formData) {
      formData.append('id', this.emailtemplate.id)
    },
    loadItem () {
      if (this.$route.params.id !== undefined) {
        let loader = this.$loading.show()
        this.fetch(this.$route.params.id)
          .then(() => {
            this.emailtemplate = this.data
            this.imageUploaded = !!this.emailtemplate.image_header
          }).then(() => {
            loader.hide()
          })
      }
    },
    popupEmailTest () {
      this.$refs.formTestEmail.open()
      this.modalForm = true
    },
    async saveEmailTemplate () {
      let loader = this.$loading.show()
      try {
        this.submitted = true
        this.$v.emailtemplate.$touch()
        if (this.$v.emailtemplate.$invalid) {
          window.scroll(0, 0)
          loader.hide()
          errorMessage('Alerta: Existem campos obrigatórios não preenchidos')
          return
        }
        await this.saveTemplate(this.emailtemplate).then(() => {
          let message = 'Sucesso: Dados atualizados com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          loader.hide()
        })
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
        loader.hide()
      }
    }
  }
}
</script>
<style scoped>
input[type="file"] {
  display: none;
}
.link { cursor: pointer; }
.preview-lg {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.logo-preview {
  width:200px;
  overflow:hidden;
  height: 65px;
}
.actions {
  margin-top: 1rem;
}
.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.bg-black { background-color: #afafaf; opacity: 1; }
.border-dashed {
  border: 2px dashed #ced4da !important;
  padding: 4px;
}
.vue-dropzone .dz-message {
  margin: 1em 0 !important;
}
.dropzone-custom-content {
  text-align: center;
}
.dropzone-custom-title {
  margin-top: 0;
  color: #0065b7;
  font-size: 18px;
}
.subtitle {
  color: #314b5f;
  font-size: 14px;
}
.footerEmail {
  text-align: center;
  padding: 5px;
  background: #F8F8FA;
  margin-bottom: 0.5rem;
}
.iconSocial {
  padding: 0;
    display: inline-block;
    height: auto;
    margin: 0;
}
.btn-trash {
  position: absolute;
  top: 10px;
  left: 20px;
}
</style>
