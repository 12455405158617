<template>
  <Layout>
    <div class="row mb-4" style="align-items: center">
      <div class="col-9">
        <h4 class="mb-0 font-size-18">Detalhes da campanha - Campanha de convites</h4>
      </div>
      <div class="col-3" style="display: flex; gap: 10px; align-items: center;">
        <span class="mb-0 font-size-16" style="font-weight: 500">Status:</span>
        <b-progress max="100" height="2rem" style="width: 100%" variant="success" v-if="campaign.progress > 0">
          <b-progress-bar :value="campaign.progress">
            <span>Progresso: <strong>{{ campaign.progress + '%' }}</strong></span>
          </b-progress-bar>
        </b-progress>
        <span v-if="campaign.progress === 0" class="badge font-size-11 p-2 badge-secondary" style="width: 100%; margin-top: 2px; background-color: #d9d9d9 !important; height: 2rem">0%</span>
      </div>
    </div>
    <e-campaign-statistic ref="child" :campaignId="campaignId" v-if="campaignId"></e-campaign-statistic>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-8">
                <h4 class="card-title mb-4">Detalhes de envio</h4>
              </div>
              <div class="col-sm-4">
                <div class="search-box mr-2 mb-2">
                  <div class="position-relative">
                    <input type="text" class="form-control" v-model="search" placeholder="Pesquise por Nome ou Telefone e pressione 'ENTER'" @keyup.enter="doSearch"/>
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th width="5%">#</th>
                    <th width="20%">Contato</th>
                    <th width="10%">E-mail</th>
                    <th width="10%">Data envio</th>
                    <th width="10%">Data recebimento</th>
                    <th width="10%">Data leitura</th>
                    <th width="10%">Data click</th>
                    <th width="10%">Status</th>
                    <th width="5%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(list, idx) in contacts" :key="idx">
                    <td>{{ `# ${idx + 1}` }}</td>
                    <td>{{ list.contact.data.name }}</td>
                    <td>{{ list.contact.data.email }}</td>
                    <td style="font-size: 12px !important">{{ list.sent_at || '-'}}</td>
                    <td style="font-size: 12px !important">{{ list.delivered_at || '-'}}</td>
                    <td style="font-size: 12px !important">{{ list.read_at || '-'}}</td>
                    <td style="font-size: 12px !important">{{ list.clicked_at || '-'}}</td>
                    <td>
                      <span
                        :class="list.statusClass"
                        class="badge font-size-12 p-2" style="min-width: 120px;margin-top:2px">
                        {{ list.status }}
                      </span>
                    </td>
                    <td>
                      <button type="button" @click="openLog(list)" class="link btn btn-outline-dark btn-sm"><i class="bx bx-zoom-in" size="md"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-sidebar id="sidebar-right" right shadow backdrop bg-variant="white" v-model="showLog" width="400px">
      <div class="px-3 py-2">
        <h1 class="card-title mb-4">
          Dados da mensagem
        </h1>
      </div>
      <div class="log-message">
        <div class="alert alert-info" v-html="formattedMessage"></div>
      </div>
      <div class="infos">
        <div class="info">
          <div class="title">
            <div class="textinfo">
              Enviada por
            </div>
          </div>
          <div class="time">
            <div class="small">
              Campanha
            </div>
          </div>
        </div>
        <div class="info">
          <div class="title">
            <div class="icon">
              <svg
                x="0px"
                y="0px"
                viewBox="0 0 11 8"
                class="sent"
              >
                <path
                  d="M3.71,8C3.52,8,3.34,7.93,3.2,7.79L0.21,4.81c-0.28-0.28-0.28-0.73,0-1.01s0.74-0.28,1.02,0l2.48,2.48l6.07-6.06c0.28-0.28,0.74-0.28,1.02,0c0.28,0.28,0.28,0.73,0,1.01L4.21,7.79C4.07,7.93,3.89,8,3.71,8z"
                ></path>
              </svg>
            </div>
            <div class="textinfo">
              Enviada
            </div>
          </div>
          <div class="time">
            <div class="small">
              {{ logMessage.sent_at }}
            </div>
          </div>
        </div>
        <div class="info">
          <div class="title">
            <div class="icon">
              <svg
                x="0px"
                y="0px"
                viewBox="0 0 15 8"
                class="delivered"
              >
                <path
                  d="M14.79,0.21c-0.28-0.28-0.73-0.28-1.01,0L7.65,6.3L6.69,5.35l4.18-4.15c0.28-0.28,0.28-0.72,0-1c-0.28-0.28-0.73-0.28-1.01,0L5.69,4.35L5.13,3.8c-0.28-0.28-0.73-0.28-1.01,0c-0.28,0.28-0.28,0.72,0,1l0.55,0.55L3.73,6.29L1.22,3.8c-0.28-0.28-0.73-0.28-1.01,0s-0.28,0.72,0,1l3.02,2.99C3.37,7.93,3.55,8,3.73,8s0.36-0.07,0.5-0.21l1.45-1.44l1.46,1.44C7.28,7.93,7.47,8,7.65,8c0.18,0,0.36-0.07,0.5-0.21l6.64-6.58C15.07,0.93,15.07,0.49,14.79,0.21z"
                ></path>
              </svg>
            </div>
            <div class="textinfo">
              Entregue
            </div>
          </div>
          <div class="time">
            <div class="small">
              {{ logMessage.delivered_at }}
            </div>
          </div>
        </div>
        <div class="info">
          <div class="title">
            <div class="icon">
              <svg
                x="0px"
                y="0px"
                viewBox="0 0 15 8"
                class="read"
              >
                <path
                  d="M14.79,0.21c-0.28-0.28-0.73-0.28-1.01,0L7.65,6.3L6.69,5.35l4.18-4.15c0.28-0.28,0.28-0.72,0-1c-0.28-0.28-0.73-0.28-1.01,0L5.69,4.35L5.13,3.8c-0.28-0.28-0.73-0.28-1.01,0c-0.28,0.28-0.28,0.72,0,1l0.55,0.55L3.73,6.29L1.22,3.8c-0.28-0.28-0.73-0.28-1.01,0s-0.28,0.72,0,1l3.02,2.99C3.37,7.93,3.55,8,3.73,8s0.36-0.07,0.5-0.21l1.45-1.44l1.46,1.44C7.28,7.93,7.47,8,7.65,8c0.18,0,0.36-0.07,0.5-0.21l6.64-6.58C15.07,0.93,15.07,0.49,14.79,0.21z"
                ></path>
              </svg>
            </div>
            <div class="textinfo">
              Lida
            </div>
          </div>
          <div class="time">
            <div class="small">
              {{ logMessage.read_at }}
            </div>
          </div>
        </div>
        <div class="info" v-if="logMessage.failed_at">
          <div class="title">
            <div class="icon">
              <svg
                x="0px"
                y="0px"
                viewBox="0 0 11 8"
                class="failed"
              >
                <path
                  d="M3.71,8C3.52,8,3.34,7.93,3.2,7.79L0.21,4.81c-0.28-0.28-0.28-0.73,0-1.01s0.74-0.28,1.02,0l2.48,2.48l6.07-6.06c0.28-0.28,0.74-0.28,1.02,0c0.28,0.28,0.28,0.73,0,1.01L4.21,7.79C4.07,7.93,3.89,8,3.71,8z"
                ></path>
              </svg>
            </div>
            <div class="textinfo">
              Falha
            </div>
          </div>
          <div class="description">
            <div class="font-size-12">{{ logMessage.response }}</div>
          </div>
          <div class="time">
            <div class="small">
              {{ logMessage.failed_at }}
            </div>
          </div>
        </div>
      </div>
        <!---->
    </b-sidebar>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import appConfig from '@/app.config'
// import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import eCampaignStatistic from '@/components/widgets/e-campaign-statistic'

export default {
  components: {
    Layout,
    eCampaignStatistic
  },
  name: 'campaign-details',
  page: {
    title: 'Campanhas - Detalhes da campanha',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      timeout: 60000,
      search: '',
      showLog: false,
      logMessage: {
        body: null
      },
      params: {
        include: 'contact'
      },
      campaign: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      submitted: false,
      title: 'Detalhes da campanha -',
      items: [{
        text: 'Campanhas'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.loadItem()
    this.loading = false
    this.GetStatus()
  },
  computed: {
    ...mapState('ecampaign', { data: 'campaign' }),
    ...mapState('ecampaign', ['contacts', 'paginationC']),
    ...mapState('settings', ['statusList']),
    rows () {
      return this.paginationC.total
    },
    campaignId () {
      return this.campaign.id
    },
    formattedMessage () {
      if (this.logMessage.body) {
        return this.logMessage.body
          .replace(/\r\n/g, '<br>')
          .replace(/\*(.*?)\*/g, '<strong>$1</strong>')
      }
      return ''
    }
  },
  methods: {
    ...mapActions('settings', { GetStatus: 'GetStatusList' }),
    ...mapActions('ecampaign', {
      getCampaign: 'show',
      getContacts: 'getContacts'
    }),
    openLog (message) {
      this.showLog = true
      this.logMessage = message
    },
    loadItem () {
      if (this.$route.params.id !== undefined) {
        let loader = this.$loading.show()
        // this.getCampaignContacts()
        this.getCampaign(this.$route.params.id)
          .then(() => {
            this.title += ' ' + this.data.name
            this.campaign = this.data
          }).then(() => {
            loader.hide()
            this.updateList()
          })
      }
    },
    getReport (filter) {
      let params = {
        include: 'contact',
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage
      }
      let loeader = this.$loading.show()
      this.params = params
      this.getContacts({ id: this.campaign.id, params: this.params }).then(() => {
        this.totalRows = this.paginationC.total
        this.currentPage = this.paginationC.current_page
        loeader.hide()
      }).catch(error => {
        loeader.hide()
        console.log(error)
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
      // this.$refs.child.getSubscribers()
    }
  },
  watch: {
    // currentPage () { this.updateList() }
    // total () { this.updateList() },
  }
}
</script>
<style scoped>
.log-message {
  padding: 10px 5px 10px 30px;
  background: #f5f5f5;
  display: flex;
  justify-content: right;
}
.infos {
  padding: 30px 25px;
}
.infos .info:not(:last-child) {
  margin-bottom: 20px;
  border-bottom: 1px solid #e8e9ed;
  padding-bottom: 20px;
}
.textinfo {
  line-height: 26px;
  font-size: 14px;
}
.infos .info .title {
  display: flex;
  align-items: center;
}
.infos .info .title .icon {
  display: flex;
  margin-right: 6px;
  width: 15px;
  justify-content: center;
  flex-shrink: 0;
}
.infos .info .title .icon svg {
  display: block;
  fill: #a2a7b6;
}
.infos .info .title .icon svg.failed, .infos .info .title .icon svg.sent {
  width: 11px;
  height: 8px;
}
.infos .info .title .icon svg.read {
  fill: #3774d0;
}
.infos .info .title .icon svg.failed {
  fill: #d03737;
}
.link { cursor: pointer; }
.inativado { background: #f7ebeb }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
.float-sm-end { float: right }
</style>
